<!--
 * @Author: dingguowei
 * @Date: 2023-06-13 10:13:41
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-07 11:17:54
-->
<!-- 任务大厅 -->
<template>
  <div class="taskHall">
    <div class="head">
      <el-input size="mini" placeholder="请输入内容" v-model="kwd" clearable prefix-icon="el-icon-search" style="width: 24rem"
        @keyup.enter.native="userGetTask">
      </el-input>
      <el-button size="mini" round @click="userGetTask"> 搜索</el-button>
    </div>
    <div class="body" v-if="taskData.length > 0">
      <task-hall-item class="item" :task-data="item" v-for="(item, index) in taskData" :key="index">
      </task-hall-item>
    </div>
    <el-empty style="flex: 1" v-else description="暂无数据"></el-empty>
    <el-pagination background layout="prev, pager, next" :total="total"
      style="margin-left: AUTO; margin-top: 10px; display: block" :pager-count="7" small @current-change="userGetTask"
      :current-page.sync="pageNo" :page-size="pageSize">
    </el-pagination>
  </div>
</template>

<script>
import taskHallItem from "@/components/user/taskHallItem.vue";
import { userGetTask, gettaskInfo } from "@/api/user";
export default {
  components: {
    taskHallItem,
  },
  data() {
    return {
      kwd: "", //关键词
      pageNo: 1,
      pageSize: 9,
      total: 0,
      taskData: [],
    };
  },
  created() { },
  mounted() {
    this.userGetTask();
  },
  watch: {},
  computed: {},
  methods: {
    async userGetTask() {
      await userGetTask({
        kwd: this.kwd,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        this.taskData = res.data.records;
        this.total = res.data.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.taskHall {
  box-sizing: border-box;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;

  .head {
    height: 120px;
    // background-color: #873535;
    background-image: url("../../assets/user/head.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep .el-button {
      color: #587aef;
      margin-left: 10px;
    }
  }

  .body {
    overflow: auto;
    box-sizing: border-box;
    flex: 1;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;

    .item {
      flex-basis: calc(33.33%);
      padding: 5px 5px;
      box-sizing: border-box;
    }

    .item--single {
      flex-basis: 100%;
    }
  }
}
</style>